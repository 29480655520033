 <template>
  <div style="height:100%" >


    <div style="padding: 10px;height: 100px ;background-color: rgb(128,128,128);border-radius: 10px">
      <!-- 搜素框 -->
      <div style="height:100%;width:100%;padding-top:2px;background-color:#fff;border-radius:8px">
        <el-form :inline="true" :model="params"
                 style=" display: grid;
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(2, 20%);
            grid-gap: 6px; /* 设置网格项之间的间距 */
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;">


           <el-form-item label="师傅名称" size="mini">
            <el-select filterable  v-model="params.masterId" placeholder="请选择师傅名称">
              <el-option v-for="item in masterList" :key="item.id" :label="(item.userName).substring(item.userName.indexOf('('), item.userName.indexOf(','))" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="品牌名称" size="mini">
            <el-select filterable  v-model="params.brandIds" multiple placeholder="请选择品牌">
              <el-option v-for="item in brandList"
                         :key="item.id"
                         :label="item.brandName" :value="item.id"/>
            </el-select>
          </el-form-item>

          <el-form-item label="型号" size="mini">
            <el-select filterable v-model="params.categoryIds" multiple placeholder="请选择型号">

              <el-option v-for="item in storeTypeList"
                         :key="item.id"
                         :label="item.categoryName" :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="统计依据" size="mini">
            <el-cascader
                v-model="params.completeRateGroupBy"
                :options="groups"
                placeholder="请选择"
                :props="{ multiple: true,
                 checkStrictly: false,
                  expandTrigger: 'hover', filterable: true,
                                   emitPath: false }"

            ></el-cascader>
          </el-form-item>
          <el-form-item label="时间范围" size="mini">
            <el-date-picker
                size="mini"
                v-model="params.rangeTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
            <!--          <el-date-picker v-model="params.startTime" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 180px;"></el-date-picker>-->
            <!--          <el-date-picker v-model="params.endTime" type="datetime" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" style="margin-left:5px;width: 180px;"></el-date-picker>-->
          </el-form-item >

          <el-form-item label="区域" size="mini">
            <el-cascader
                size= ""
                :options="options"
                v-model="fullLocalId"
                :props="{
                multiple: true,
                children: 'children',
                filterable: true,
                checkStrictly: true, // 允许选择任意一级
                emitPath: false // 返回完整路径
          }"
            >

            </el-cascader>
          </el-form-item>

          <div style="display: flex;height: 30px;      justify-content: center; /* 水平居中对齐子元素 */
         align-items: center; grid-column: 1 / -1; /* 让该子元素独占从第一列到最后一列，即独占整行 */; /* 让该子元素占满一整行 */;
         justify-content: center;margin-left: -10%;margin-top: 0%">
                    <el-button @click="checkData" type="primary" size="mini">查询</el-button>
                    <el-button type="info" @click="reset" size="mini">重置</el-button>
          </div>


        </el-form>
      </div>

    </div>
      <!-- 表格 -->
      <div style="height:80%; width: 100%;background-color: white;margin-top: 0; border-radius: 20px; display: flex; justify-content: center; align-items: center;">
        <div style="width: 100%; height: 90%; margin-top:-7%;margin-left: 5%;margin-right: 5%">
          <!-- 导出按钮可以取消注释，启用功能 -->
          <!-- <el-button type="success" size="small" style="float: right; margin-top: 20px; margin-bottom: 20px;" @click="toExcel">导出Excel</el-button> -->

          <div style="display: flex; justify-content: flex-end; gap: 10px; margin-top: 20px; margin-bottom: 10px;">
          <!-- 导出按钮 -->
          <el-button
              type="success"
              size="small"
              style="float: right; margin-top: 20px; margin-bottom: 10px;"
              @click="exportToExcel"
          >
            导出选中数据
          </el-button>
          <el-button
              type="success"
              size="small"
              style="float: right; margin-top: 20px; margin-bottom: 10px;"
              @click="exportAllToExcel"
          >
            导出全部数据
          </el-button>
        </div>

          <el-table height="93%" :data="formattedTableData" stripe :fit="true" size="mini" :reserve-selection="true" :row-key="getRowKey"
                    :header-cell-style="{'background-color':'#84aace4a','color':'#343333','text-align':'center','border-right': '1px solid white' }"
       @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <!-- 师傅姓名列 -->
            <el-table-column v-if="hasUsername" prop="username" label="师傅姓名" align="center"></el-table-column>
            <!-- 省名（如果省名存在，则显示） -->
            <el-table-column v-if="hasProvinceName" prop="provinceName" label="省名" align="center"></el-table-column>

            <!-- 市名（如果市名存在，则显示） -->
            <el-table-column v-if="hasCityName" prop="cityName" label="市名" align="center"></el-table-column>

            <!-- 区名（如果区名存在，则显示） -->
            <el-table-column v-if="hasAreaName" prop="areaName" label="区县名" align="center"></el-table-column>

            <!--品牌名 -->
            <el-table-column v-if="hasBrandName" prop="brandName" label="品牌名" align="center"></el-table-column>
            <!-- 型号 -->
            <el-table-column v-if="hasStoreName" prop="storeName" label="型号名" align="center"></el-table-column>


            <!-- 查询起始时间 -->
            <el-table-column v-if="hasStartTime" prop="startTime" label="查询起始时间" :formatter="dateStartFormat" align="center"></el-table-column>

            <!-- 查询结束时间 -->
            <el-table-column v-if="hasEndTime" prop="endTime" label="查询结束时间" :formatter="dateEndFormat" align="center"></el-table-column>

            <!-- 派单数 -->

            <el-table-column v-if="hasDistributeCount" sortable :sort-method="(a, b) => a.distributeCount - b.distributeCount" prop="distributeCount" label="派单数" align="center"></el-table-column>

            <!-- 完工数目 -->
            <el-table-column v-if="hasCompleteCount"  sortable  prop="completeCount" :sort-method="(a, b) => a.completeCount - b.completeCount" label="完工数" align="center" ></el-table-column>




            <!-- 待安装数目 -->

            <el-table-column  sortable :sort-method="(a, b) => a.beInstallCount - b.beInstallCount" prop="beInstallCount" label="待安装" align="center"></el-table-column>
            <!-- 驳回数目 -->
            <el-table-column  sortable :sort-method="(a, b) => a.rejectedCount - b.rejectedCount" prop="rejectedCount" label="驳回" align="center"></el-table-column>
            <!-- 待上游审核 -->
            <el-table-column   sortable :sort-method="(a, b) => a.preExamineCount - b.preExamineCount" prop="preExamineCount" label="待上游审核" align="center"></el-table-column>

            <!-- 待上游审核 -->
            <el-table-column sortable :sort-method="(a, b) => a.examinedCount - b.examinedCount" prop="examinedCount" label="已审核" align="center"></el-table-column>

            <!-- 完工率(%) -->
            <el-table-column sortable   :sort-method="(a, b) => a.completeRate - b.completeRate" prop="completeRate" label="完工率(%)" align="center"></el-table-column>

          </el-table>

          <div style="padding-top: 10px;margin-top: 6px; height: 40px; background-color: #fff; width: 100%; border-radius: 20px; text-align: right;">
            <el-pagination background :page-sizes="sizes" layout="total, sizes, prev, pager, next" :total="totalx"
                           @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
          </div>

<!--          &lt;!&ndash; 分页组件 &ndash;&gt;-->
<!--          <div style="padding-top: 20px; height: 40px; background-color: #fff; width: 100%; border-radius: 8px; text-align: right;">-->
<!--            <el-pagination-->
<!--                background-->
<!--                :page-sizes="sizes"-->
<!--                :page-size="pageSize"-->
<!--                layout="total, sizes, prev, pager, next"-->
<!--                :total="total"-->
<!--                @current-change="handleCurrentChange"-->
<!--                @size-change="sizeChange">-->
<!--            </el-pagination>-->
<!--          </div>-->
        </div>
      </div>

    </div>





</template>
<script>
import * as XLSX from 'xlsx';
import { nanoid } from 'nanoid';


import moment from 'moment'
import { regionData ,codeToText} from 'element-china-area-data'
export default {
  computed: {
    formattedTableData() {
      return this.tableData.map(item => ({
        ...item,
        completeRate: item.distributeCount === 0 ? 0 : ((item.completeCount / item.distributeCount) * 100).toFixed(0),
        id: nanoid()  // 使用 UUID 生成唯一 id
      }));
    },
    formattedFullTableData() {
      return this.fullTableData.map(item => ({
        ...item,
        completeRate: item.distributeCount === 0 ? 0 : ((item.completeCount / item.distributeCount) * 100).toFixed(0),
        id: nanoid()  // 使用 UUID 生成唯一 id
      }));
    },
    // 判断是否有“师傅姓名”列
    hasUsername() {
      return this.tableData.some(item => item.username && item.username.trim() !== '');
    },
    // 判断是否有查询起始时间列
    hasStartTime() {
      return this.tableData.some(item => item.startTime && item.startTime.trim() !== '');
    },
    // 判断是否有查询结束时间列
    hasEndTime() {
      return this.tableData.some(item => item.endTime && item.endTime.trim() !== '');
    },
    // 判断是否有派单数列
    hasDistributeCount() {
      return this.tableData.some(item => item.distributeCount && item.distributeCount !== null);
    },
    // 判断是否有完工数目列
    hasCompleteCount() {
      return this.tableData.some(item => item.completeCount && item.completeCount !== null);
    },
    // 判断是否有完工率列
    hasCompleteRate() {
      return this.tableData.some(item => item.completeRate && item.completeRate !== null);
    },
    // 判断是否有省名列
    hasProvinceName() {
      return this.tableData.some(item => item.provinceName && item.provinceName.trim() !== '');
    },
    // 判断是否有市名列
    hasCityName() {
      return this.tableData.some(item => item.cityName && item.cityName.trim() !== '');
    },
    // 判断是否有市名列
    hasBrandName() {
      return this.tableData.some(item => item.brandName && item.brandName.trim() !== '');
    },
    // 判断是否有市名列
    hasStoreName() {
      return this.tableData.some(item => item.storeName && item.storeName.trim() !== '');
    },
    // 判断是否有区名列
    hasAreaName() {
      return this.tableData.some(item => item.areaName && item.areaName.trim() !== '');
    }
  },
  data() {
    return {
      multipleSelection: [],
      groups: [
        { value: 'brandGroupNeed', label: '品牌' },
        { value: 'storeGroupNeed', label: '型号' },
        {
          value: 'region',
          label: '区域级别',
          children: [
            {
              value: 'provinceLevelGroupNeed',
              label: '省'
            },
            {
              value: 'cityLevelGroupNeed',
              label: '市'
            },
            {
              value: 'areaLevelGroupNeed',
              label: '区'
            }
          ]
        }
      ],
      fullLocalId: [],
      options: regionData,
      selectedOptions: [],
      // 列名映射
      columnLabels: {
        username: '师傅姓名',
        startTime: '查询起始时间',
        endTime: '查询结束时间',
        distributeCount: '派单数',
        completeCount: '完工数',
        beInstallCount: '待安装',
        rejectedCount: '驳回',
        preExamineCount: '待上游审核',
        examinedCount: '已审核',
        completeRate: '完工率(%)',
        provinceName: '省名',
        cityName: '市名',
        areaName: '区县名',
        brandName: '品牌名',
        storeName: '型号名',
      },
      options2: [

     {
        value: 'brand_name',
        label: '品牌'
      }, {
        value: 'store_name',
        label: '型号'
      }
      ],
      value1: [],
      queryLevel: "",
      regionNames: [],
      storeTypeList: [],
      tableData: [],
      fullTableData: [],
      masterList:[],
      regionList: [],
      brandList: [],
      params: {
        rangeTime: '',
        completeRateGroupBy: [],
        endTime: '',
        startTime: '',
        localList: [],
        masterId: '',
        brandIds:[],
        categoryIds: [],
        page: 1,
        size: 15,
      },
      totalx: 0,
      sizes: [15, 34,60,100,200,400,800],
    };
  },
  created() {
    this.queryClassList();
    this.queryCompleteRateList()  ;
    this.queryMasterList();
    this.queryRegionList();
    this.queryDeviceBrandDrown();
  },
  watch: {
    fullLocalId: {
      handler(newVal, oldVal) {
        const addedValues = newVal.filter(item => !oldVal.includes(item));
        // 监控 fullLocalId 的变化
        if (addedValues.length > 0) {
          this.checkSelection(newVal, addedValues);
        }
      },
      deep: true
    }
  },
  methods: {
    getRowKey(row) {
      console.log(row.id);
      return row.id; // 假设每一行有唯一的 id 字段
    },
    exportAllToExcel(){
      this.params.startTime = this.dateStartFormat(this.params.rangeTime[0]);
      this.params.endTime = this.dateEndFormat(this.params.rangeTime[1]);
      console.log(this.params);
      this.axios({
        method: "post",
        url: "/v1/web/order-info/complete-order-rate-full",
        data: this.params,
      }).then((res) => {
        console.log(res.data.data);
        this.fullTableData = res.data.data;
        this.exportToExcel2();
      });
     
    },
    exportToExcel2(){
       // 需要固定导出的列
       const columnsToAdd = ['beInstallCount', 'rejectedCount', 'preExamineCount', 'examinedCount', 'completeRate'];
      const visibleColumns = [];
      if (this.hasProvinceName) {
        visibleColumns.push('provinceName');
      }
      if (this.hasCityName) {
        visibleColumns.push('cityName');
      }
      if (this.hasAreaName) {
        visibleColumns.push('areaName');
      }
      if (this.hasBrandName) {
        visibleColumns.push('brandName');
      }
      if (this.hasStoreName) {
        visibleColumns.push('storeName');
      }
      // 根据 v-if 控制的列动态加入
      if (this.hasUsername) {
        visibleColumns.push('username');
      }
      if (this.hasStartTime) {
        visibleColumns.push('startTime');
      }
      if (this.hasEndTime) {
        visibleColumns.push('endTime');
      }
      if (this.hasDistributeCount) {
        visibleColumns.push('distributeCount');
      }
      if (this.hasCompleteCount) {
        visibleColumns.push('completeCount');
      }


      // 添加固定列
      for (let i = 0; i < columnsToAdd.length; i++) {
        if (!visibleColumns.includes(columnsToAdd[i])) {
          visibleColumns.push(columnsToAdd[i]);
        }
      }

      // 列名映射表（英文 -> 中文）
      const columnNameMap = {
        'username': '师傅姓名',
        'startTime': '开始时间',
        'endTime': '结束时间',
        'distributeCount': '分配数量',
        'completeCount': '完成数量',
        'provinceName': '省份',
        'cityName': '城市',
        'areaName': '区域',
        'brandName': '品牌',
        'storeName': '门店',
        'beInstallCount': '待安装',
        'rejectedCount': '驳回',
        'preExamineCount': '待上游审核',
        'examinedCount': '已审核',
        'completeRate': '完工率'
      };

      // 设置中文表头
      const headers = visibleColumns.map(column => columnNameMap[column] || column);

      // 如果有选中的行，则只导出选中的数据，否则导出所有数据
      const dataToExport = this.formattedFullTableData;
      

      // 筛选数据，只保留可见的列
      const filteredData = dataToExport.map(row => {
        const filteredRow = {};
        visibleColumns.forEach(column => {
          filteredRow[columnNameMap[column] || column] = row[column];  // 使用中文列名作为键
        });
        return filteredRow;
      });

      // 创建 Excel 工作表，并设置表头为中文
      const ws = XLSX.utils.json_to_sheet(filteredData, { header: headers });

      // 创建工作簿
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

// 获取当前日期和时间戳
      const now = new Date();
      const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_`
          + `${now.getHours().toString().padStart(2, '0')};${now.getMinutes().toString().padStart(2, '0')}`;

// 使用当前日期时间戳生成文件名
      const fileName = `表单数据_${timestamp}.xlsx`;

// 导出文件
      XLSX.writeFile(wb, fileName);
    },
    exportToExcel() {
      // 需要固定导出的列
      const columnsToAdd = ['beInstallCount', 'rejectedCount', 'preExamineCount', 'examinedCount', 'completeRate'];
      const visibleColumns = [];
      if (this.hasProvinceName) {
        visibleColumns.push('provinceName');
      }
      if (this.hasCityName) {
        visibleColumns.push('cityName');
      }
      if (this.hasAreaName) {
        visibleColumns.push('areaName');
      }
      if (this.hasBrandName) {
        visibleColumns.push('brandName');
      }
      if (this.hasStoreName) {
        visibleColumns.push('storeName');
      }
      // 根据 v-if 控制的列动态加入
      if (this.hasUsername) {
        visibleColumns.push('username');
      }
      if (this.hasStartTime) {
        visibleColumns.push('startTime');
      }
      if (this.hasEndTime) {
        visibleColumns.push('endTime');
      }
      if (this.hasDistributeCount) {
        visibleColumns.push('distributeCount');
      }
      if (this.hasCompleteCount) {
        visibleColumns.push('completeCount');
      }


      // 添加固定列
      for (let i = 0; i < columnsToAdd.length; i++) {
        if (!visibleColumns.includes(columnsToAdd[i])) {
          visibleColumns.push(columnsToAdd[i]);
        }
      }

      // 列名映射表（英文 -> 中文）
      const columnNameMap = {
        'username': '师傅姓名',
        'startTime': '开始时间',
        'endTime': '结束时间',
        'distributeCount': '分配数量',
        'completeCount': '完成数量',
        'provinceName': '省份',
        'cityName': '城市',
        'areaName': '区域',
        'brandName': '品牌',
        'storeName': '门店',
        'beInstallCount': '待安装',
        'rejectedCount': '驳回',
        'preExamineCount': '待上游审核',
        'examinedCount': '已审核',
        'completeRate': '完工率'
      };

      // 设置中文表头
      const headers = visibleColumns.map(column => columnNameMap[column] || column);

      // 如果有选中的行，则只导出选中的数据，否则导出所有数据
      const dataToExport = this.multipleSelection.length > 0 ? this.multipleSelection : this.formattedTableData;
    
      // 筛选数据，只保留可见的列
      const filteredData = dataToExport.map(row => {
        const filteredRow = {};
        visibleColumns.forEach(column => {
          filteredRow[columnNameMap[column] || column] = row[column];  // 使用中文列名作为键
        });
        return filteredRow;
      });

      // 创建 Excel 工作表，并设置表头为中文
      const ws = XLSX.utils.json_to_sheet(filteredData, { header: headers });

      // 创建工作簿
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

// 获取当前日期和时间戳
      const now = new Date();
      const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_`
          + `${now.getHours().toString().padStart(2, '0')};${now.getMinutes().toString().padStart(2, '0')}`;

// 使用当前日期时间戳生成文件名
      const fileName = `表单数据_${timestamp}.xlsx`;

// 导出文件
      XLSX.writeFile(wb, fileName);

    },
    calculateCompleteRate(row) {
      if (row.distributeCount === 0) {
        return 0; // 防止除以0
      }
      return (row.completeCount / row.distributeCount) * 100;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleChange(value) {
      console.log('当前选中项：', value);
      // 获取选中项中属于“区域级别”的选项
      const regionOptions = value.filter(item => ['province', 'city', 'district'].includes(item));

      // 如果选择了多个区域选项，保留最新选择的一个
      if (regionOptions.length > 1) {
        const lastSelected = regionOptions[regionOptions.length - 1];
        // 更新选择，仅保留“品牌”“型号”和最新的区域级别选项
        this.params.completeRateGroupBy = value.filter(
            item => !['province', 'city', 'district'].includes(item) || item === lastSelected
        );

        this.$message.warning("在区域级别中只能选择一个省、市或区！");
      }
    },
    checkSelection(selectedIds, addItem) {
      if (selectedIds.length === 1) {
        this.updateQueryLevel(addItem[0]);
        return;
      }
      let add="";
      if (addItem[0] < 100) {
        add = "省";
      } else if (addItem[0]  < 9999) {
        add= "市";
      } else if (addItem[0]  > 10000) {
        add = "区";
      }
      if (add!==this.queryLevel){
        this.$notify({
          title: '提示信息',
          message: '请勿同时勾选 省 市 区',
          type: 'error',
        });
        this.fullLocalId =  this.fullLocalId.filter(item => item !== addItem[0]);
        console.log(this.fullLocalId)
      }
    },
    updateQueryLevel(id) {
      if (id < 100) {
        this.queryLevel = "省";
      } else if (id < 9999) {
        this.queryLevel = "市";
      } else if (id > 10000) {
        this.queryLevel = "区";
      }
    },
    updateLocalList() {
      this.params.localList = this.getCodeToText(this.fullLocalId);
    },
    getCodeToText(codeArray) {
      console.log("ID",codeArray)
      if (null === codeArray) {
        return null;
      } else if (null === codeArray) {
       return null;
      }

      let area = [];
      const length = codeArray.length; // 最多处理三个元素

      for (let i = 0; i < length; i++) {
        if(codeArray[i]<99){
          area.push(codeToText[codeArray[i]]);
          continue;
        }
        if (codeArray[i]<9999){
          let firstTwoDigits = parseInt(codeArray[i].toString().substring(0, 2), 10);
          console.log(firstTwoDigits)
          let fullName= codeToText[firstTwoDigits]+"/"+codeToText[codeArray[i]];
          area.push(fullName);
          continue;
        }

        if (codeArray[i]<999999){
          let firstTwoDigits = parseInt(codeArray[i].toString().substring(0, 2), 10);
          let firstFourDigits = parseInt(codeArray[i].toString().substring(0, 4), 10);
          console.log(firstTwoDigits)
          let fullName= codeToText[firstTwoDigits]+"/"+codeToText[firstFourDigits]+"/"+codeToText[codeArray[i]];
          area.push(fullName);
        }
      }
      return area;
    },
    queryMasterList(){//所属师傅下拉（包含暂不接单的师傅）
      this.axios({
        method: "get",
        url: "/v1/web/user-info/select-master-drown"
      }).then((res) => {
        this.masterList = []
        let obj = {}
        obj.id = 0;
        obj.userName = "全部("
        this.masterList.push(obj)
        this.masterList.push(... res.data.data.list)
      });
    },
    queryClassList() {
      this.axios({
        method: 'get',
        url: '/v1/web/device-category/select-category',
      }).then((res) => {
        this.storeTypeList = res.data.data.list;
        console.log("HI3",this.storeTypeList);
      });
    },
    queryDeviceBrandDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-brand/select-device-brand',
				}).then((res) => {
					this.brandList = res.data.data.list;
				});
			},
    queryRegionList() {
				//所属区域下拉
				this.axios({
					method: 'get',
					url: '/v1/web/region-manage/select-region-drop-down',
				}).then((res) => {
					// this.regionList=[]
					// let obj = {}
					// obj.id = 0;
					// obj.regionName = "全部"
					// this.regionList.push(obj)
          // console.log(...res.data.data.list)
					this.regionList.push(...res.data.data.list);
				});
			},
    queryCompleteRateList(){//获取列表信息
      // this.params.size=17;
      this.params.startTime = this.dateStartFormat(this.params.rangeTime[0]);
      this.params.endTime = this.dateEndFormat(this.params.rangeTime[1]);
      console.log(this.params);
      this.axios({
        method: "post",
        url: "/v1/web/order-info/complete-order-rate",
        data: this.params,
      }).then((res) => {
        console.log("发出请求")
        this.tableData = res.data.data.list;
        console.log("数据为",this.tableData);
        this.totalx = res.data.data.total;
      });
    },
    toExcel(){//获取列表信息
      this.axios({
        method: "get",
        url: "/v1/web/order-info/export-settlement",
        params: this.params,
        responseType: "blob"
      }).then((res) => {
        let filename=res.headers['content-disposition']
        const link=document.createElement("a");
        let blob=new Blob([res.data], { type: "application/octet-stream" }); //类型excel
        link.style.display="none";
        link.href=URL.createObjectURL(blob);
        link.setAttribute("download", decodeURI(filename.substring(filename.indexOf("=")+1)));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    dateStartFormat:function(row){
      if(row !== null && row !== undefined){
        return moment(row).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    dateEndFormat:function(row){
      if(row !== null && row !== undefined){
        return moment(row).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.queryCompleteRateList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryCompleteRateList();
    },
    checkData() {
      this.updateLocalList();
      this.params.startTime=this.params.rangeTime[0];
      this.params.endTime=this.params.rangeTime[1];
      this.queryCompleteRateList();
    },
    reset() {
      this.params = {
        rangeTime: '',
        storeName: '',
        groupBy: [],
        endTime: '',
        startTime: '',
        localList: [],
        brandName: '',
        masterId: '',
        brandIds: '',
        categoryIds: '',
        page: 1,
        size: 15,
      };
      this.fullLocalId = '';
      this.queryCompleteRateList();
    }
  }
};


</script>

<style scoped>

</style>
